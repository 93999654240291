jQuery(function ($) {
    $('.hamburger').on('click', function () {
        $(this).parents().find('header').toggleClass('open');
    })

    console.log('start');

    var scroll = new SmoothScroll('a[href*="#"]', {
        header: 'header',
        speedAsDuration:true,
        speed:500,
    });

    //固定ヘッダーの指定
    var headerHeight = $('header').outerHeight();
    // ページ外アンカーのページ付きリンクへのスムーススクロール
    var urlHash = location.hash;
    if (urlHash) {
        $('body,html').stop().scrollTop(0);
        setTimeout(function () {
            var target = $(urlHash);
            var position = target.offset().top - headerHeight;
            $('body,html').stop().animate({ scrollTop: position }, 500);
        }, 100);
    }

    if ( $('body.works-archive').length > 0 ) {
        var imagesLoadedTarget = '';
        var selectorWrap = '';
        var selectorItem = '';

        //imagesLoadedTarget = $('#biru .works-archive-grid');
        imagesLoadedTarget = $('.works-archive-grid');


        $('main').imagesLoaded(function(){
            $.each(categoryList,function(idx,val){
                selectorWrap = "#" + val + " .works-archive-grid";
                selectorItem = "#" + val + " .works-archive-grid li";

                if (selectorWrap != "" ) {

                    $(selectorWrap).masonry({
                        // options...
                        itemSelector: selectorItem ,
                        isFitWidth: true,
                        gutter: 0,
                        transitionDuration: 0,
                        //columnWidth: 300
                    });
                }
            });

            /*
            $('#biru .works-archive-grid').masonry({
                // options...
                itemSelector: '#biru .works-archive-grid li' ,
                isFitWidth: true,
                gutter: 0,
                transitionDuration: 0,
                //columnWidth: 300
            });

            $('#housing .works-archive-grid').masonry({
                // options...
                itemSelector: '#housing .works-archive-grid li' ,
                isFitWidth: true,
                gutter: 0,
                transitionDuration: 0,
                //columnWidth: 300
            });

            $('#apart .works-archive-grid').masonry({
                // options...
                itemSelector: '#apart .works-archive-grid li' ,
                isFitWidth: true,
                gutter: 0,
                transitionDuration: 0,
                //columnWidth: 300
            });
            */
        });

    }

    if ( $('body.works-detail').length > 0 ) {
        var mainSlide = $('.works-detail-img-wrap');
        mainSlide.slick({
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""></button>',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""></button>',
        });

        $('.works-detail-thumb-wrap img').on('click',function(){
            mainSlide.slick('slickGoTo',$(this).data('slideidx'),false);
            $('.works-detail-thumb-wrap img').removeClass('current');
            $(this).addClass('current');
        })

        /*
        var jsGrid = $('.works-detail-thumb-wrap');
        jsGrid.imagesLoaded(function(){
            jsGrid.masonry({
            // options...
            itemSelector: '.works-detail-thumb-wrap img',
            isFitWidth: true,
            gutter: 1,
            transitionDuration: 0,
            //columnWidth: 300
            });
        })
        */
    }
});
