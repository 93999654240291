import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'slick-carousel';
import 'jquery.cookie';
import 'jquery-inview';

import imagesLoaded from 'imagesloaded';

import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';
jQueryBridget( 'masonry', Masonry, $ );
jQueryBridget( 'imagesLoaded', imagesLoaded, $ );

require('./module/_index');
